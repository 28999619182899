<template>

    <div>

        <div>
            <img src="../../../assets/images/logo-changex-white-test.png" alt="Logo ChangeX">
        </div>

        <div class="py-3 m-auto">
            <p>Sicurezza su misura, <br> vita senza preoccupazioni.</p>
        </div>

        <div class="m-auto">
            <ul>

            <li><FontAwesomeIcon icon="fa-brands fa-x-twitter" /></li>
            <li class="mx-3" ><FontAwesomeIcon icon="fa-brands fa-facebook" /></li>
            <li class="me-3" ><FontAwesomeIcon icon="fa-brands fa-linkedin" /></li>
            <li><FontAwesomeIcon icon="fa-brands fa-instagram" /></li>
            
            </ul>
        </div>

    </div>
    
  </template>

<script>
export default {
  name: 'FooterFirst',
}
</script>
  
  <style scoped>
  
    ul{
      display: flex;
      align-items: center;
      padding: 0;
    }
  
    li{
      list-style-type: none;
      color: #FFFFFF;
      font-size: 15px;
      border: 2px solid var(--secondary-color);
      padding: 7px 11px;
      border-radius: 17px;
    }

    p{
      color: #FFFFFF;
      font-size: 15px;
      font-weight: 400;
    }
  
    img{
      height: 100px;
      width: auto;
      color: #303030;
    }  

  </style>