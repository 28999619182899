<template>
  <div class="contact-form">
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Nome" required>
      </div>
      <div class="form-group">
        <input type="text" id="surname" v-model="form.surname" class="form-control" placeholder="Cognome" required>
      </div>
      <div class="form-group">
        <input type="email" id="email" v-model="form.email" class="form-control" placeholder="Email" required>
      </div>
      <div class="form-group">
        <select id="insurance-type" v-model="form.insurance_type" class="form-control" :disabled="isFixed" required >
          <option v-if="defaultInsuranceType" :value="defaultInsuranceType.value" >
            {{ defaultInsuranceType.text }}
          </option>
          <option v-for="option in filteredOptions" :value="option.value" :key="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>
      <button type="submit" class="btn btn-primary" :disabled="isLoading">
        {{ isLoading ? 'Invio...' : 'Invia' }}
      </button>
      <p v-if="statusMessage">{{ statusMessage }}</p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'InsuranceForm',
  props: {
    defaultInsuranceType: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      form: {
        name: '',
        surname: '',
        email: '',
        insurance_type: this.defaultInsuranceType ? this.defaultInsuranceType.value : ''
      },
      options: [
        { value: '', text: 'Seleziona un tipo di assicurazione' },
        { value: 'vita', text: 'Assicurazione Vita' },
        { value: 'casa', text: 'Assicurazione Casa' },
        { value: 'auto', text: 'Assicurazione Auto' },
        { value: 'sanitaria', text: 'Assicurazione Sanitaria' },
        { value: 'cyber-security', text: 'Assicurazione Cyber Security' },
        { value: 'immobili-commerciali', text: 'Assicurazione Immobili Commerciali' },
        { value: 'cani-e-gatti', text: 'Assicurazione Cani e Gatti' },
        { value: 'viaggio', text: 'Assicurazione Viaggio' },
        { value: 'sportiva', text: 'Assicurazione Sportiva' },
        { value: 'assistenza-legale', text: 'Assistenza Legale' },
        { value: 'professionisti', text: 'Assicurazione Professionisti' },
        { value: 'eventi-atmosferici', text: 'Assicurazione Eventi Atmosferici' },
        { value: 'infortuni', text: 'Assicurazione Infortuni' },
        { value: 'cauzioni', text: 'Cauzioni' }
      ],
      statusMessage: '',
      isLoading: false
    };
  },
  computed: {
    isFixed() {
      return !!this.defaultInsuranceType;
    },
    filteredOptions() {
      if (this.defaultInsuranceType) {
        return this.options.filter(option => option.value !== this.defaultInsuranceType.value);
      }
      return this.options;
    }
  },
    watch: {
      'defaultInsuranceType.value': function(newValue) {
      if (newValue) {
        this.form.insurance_type = newValue;
      }
    }
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      try {
        const response = await axios.post('http://127.0.0.1:8000/api/contact-form', this.form);
        this.statusMessage = response.data.message;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.statusMessage = error.response && error.response.data.message ? error.response.data.message : 'Failed to submit data.';
        this.isLoading = false;
      }
    }
  },
  created() {
    if (this.defaultInsuranceType) {
      this.form.insurance_type = this.defaultInsuranceType.value;
    }
  }
};
</script>

<style scoped>
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
}

.form-control {
  background-color: #FFFFFF;
  border: 1px solid #dfe3e7;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: var(--text-gray);
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: var(--secondary-color);
}

</style>
