<template>
  <div class="hero_home">
    <div class="content">
      <h1>Personalizzata <br>
        <span style="color: var(--secondary-color);">Innovativa</span> <br>
        Vantaggiosa
      </h1>
      <p>Molto più di un'assicurazione</p>
      <button type="button" @click.prevent="scrollToSection('form-preventivo')" class="btn">Assicurati</button>
    </div>
  </div>
</template>

    <script>
        export default {
            name: 'HeroHome',
            methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
        }
        
    </script>
  
  <style scoped>
  .hero_home {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: var(--background-color);
    overflow: hidden;
  }
  
  .hero_home::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: 
    linear-gradient(45deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%),
    url('../assets/images/background-square-hero.png') left,
    url('../assets/images/hero-image.jpg') right; 
    background-size: cover, contain, cover; 
    background-position: right, left, right;
    background-repeat: no-repeat, no-repeat, no-repeat;
    opacity: 0.8;
    z-index: 1;
  }
  
  .content {
    z-index: 1;
    text-align: left;
    width: 85%;
    margin: auto;
  }
  
  h1 {
    font-size: 6vw;
    font-weight: 400;
    color: var(--accent-color);
  }
  
  p {
    font-size: 26px;
    font-weight: 500;
    color: var(--accent-color);
    padding: 15px 0px;
  }
  
  .sub_title {
    font-size: 24px;
    font-weight: 500;
    color: var(--secondary-color);
  }
  
  .btn {
    background-color: var(--secondary-color);
    padding: 17px 40px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: darken(var(--secondary-color), 10%);
  }
  
  @media (max-width: 1200px) {
    h1 {
      font-size: 7vw;
    }
  
    p {
      font-size: 4vw;
    }
  }
  
  @media (max-width: 768px) {
    .hero_home {
      background-position: center;
      text-align: center;
      align-items: center;
    }
  
    h1 {
      font-size: 8vw;
    }
  
    p {
      font-size: 5vw;
    }
  }
  
  @media (max-width: 576px) {

    .hero_home::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: url('../assets/images/hero-image.jpg') right; 
    background-size: cover; 
    background-position: right;
    background-repeat: no-repeat;
    opacity: 0.4;
  }

    h1 {
      font-size: 10vw;
    }
  
    p {
      font-size: 6vw;
    }
  
    .btn {
      padding: 15px 30px;
      font-size: 14px;
    }
  }
  </style>
  