<template>
  <div class="background_insurance text-start">
    <div class="w-75 m-auto">
      <div class="d-flex space-around pb-5 container_text_insurance">
        <div class="col pe-5">
          <ArrowTitle :title="arrows.title" />
          <h2>Noi <span style="color: var(--secondary-color);">risolviamo</span> tutti i tuoi problemi assicurativi</h2>
        </div>
        <div class="col pt-5">
          <p>Grazie alla nostra vasta esperienza e alle soluzioni altamente personalizzate, ti garantiamo una gestione completa delle tue esigenze assicurative, dalla consulenza iniziale all'assistenza costante nel corso del tempo, per una protezione totale ed una tranquillità duratura</p>
        </div>
      </div>
      <CustomCarousel :insboxes="insboxes" />
    </div>
  </div>
</template>

<script>
import ArrowTitle from './partials/ArrowTitle.vue';
import CustomCarousel from './partials/CustomCarousel.vue';

export default {
  name: 'InsuranceSolutions',
  components: {
    ArrowTitle,
    CustomCarousel
  },
  data() {
    return {
      arrows: {
        title: 'I NOSTRI SERVIZI',
      },
      insboxes: [
        {
          id: 1,
          title: 'Assicurazione auto',
          image: 'insurance-auto.jpg',
          icon: ['fa-solid', 'fa-car']
        },
        {
          id: 2,
          title: 'Assicurazione vita',
          image: 'Insurance-famiglia.jpg',
          icon: ['fa-solid', 'shield-heart']
        },
        {
          id: 3,
          title: 'Assicurazione casa',
          image: 'insurance-casa.jpg',
          icon: ['fa-solid', 'house-chimney']
        },
        {
          id: 4,
          title: 'Assicurazione sanitaria',
          image: 'insurance-salute.jpg',
          icon: ['fa-solid', 'fa-heart-pulse']
        },
        {
          id: 5,
          title: 'Assicurazione cyber security',
          image: 'insurance-cyber.jpg',
          icon: ['fa-solid', 'fa-laptop']
        },
        {
          id: 6,
          title: 'Assicurazione immobili commerciali',
          image: 'insurance-immobili.jpg',
          icon: ['fa-solid', 'fa-house-fire']
        },
        {
          id: 7,
          title: 'Assicurazione cane e gatto',
          image: 'insurance-cane.jpg',
          icon: ['fa-solid', 'fa-dog']
        },
        {
          id: 8,
          title: 'Assicurazione viaggio',
          image: 'insurance-passport.jpg',
          icon: ['fa-solid', 'fa-truck-plane']
        },
        {
          id: 9,
          title: 'Assistenza legale',
          image: 'insurance-legal.jpg',
          icon: ['fa-solid', 'fa-scale-balanced']
        },
        {
          id: 10,
          title: 'Assicurazione professionisti',
          image: 'insurance-professionisti.jpg',
          icon: ['fa-solid', 'fa-suitcase']
        },
        {
          id: 11,
          title: 'Assicurazione eventi atmosferici',
          image: 'insurance-atmosferica.jpg',
          icon: ['fa-solid', 'fa-cloud-bolt']
        },
        {
          id: 12,
          title: 'Assicurazione sportiva',
          image: 'insurance-sportiva.jpg',
          icon: ['fa-solid', 'fa-baseball']
        },
        {
          id: 13,
          title: 'Assicurazione infortuni',
          image: 'insurance-infortuni.jpg',
          icon: ['fa-solid', 'fa-crutch']
        },
        {
          id: 14,
          title: 'Cauzioni',
          image: 'insurance-cauzioni.jpg',
          icon: ['fa-solid', 'fa-building-columns']
        }
      ]
    };
  }
};
</script>

<style scoped>
.background_insurance {
  background-color: var(--background-color);
  padding: 90px 0px;
}

.container_text_insurance {
  padding-left: 35px;
}

@media (max-width: 1199px) {
  .container_text_insurance {
    flex-direction: column;
    padding-left: 0;
  }
}

h2 {
  font-size: 45px;
  font-weight: 700;
  color: var(--accent-color);
}

p {
  font-size: 18px;
  font-weight: 400;
  color: var(--text-gray);
}
</style>
