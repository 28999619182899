<template>
  <div class="contact-form">
    <form @submit.prevent="submitForm">
      <div class="form-group d-flex">
        <input type="text" id="name" v-model="form.name" class="form-control w-50" placeholder="Nome" required>
        <input type="text" id="surname" v-model="form.surname" class="form-control w-50 ms-3" placeholder="Cognome" required>
      </div>
      <div class="form-group d-flex">
        <input type="email" id="email" v-model="form.email" class="form-control w-50" placeholder="Email" required>
        <input type="tel" id="telephone" v-model="form.telephone" class="form-control w-50 ms-3" placeholder="Numero di telefono" required>
      </div>
      <div class="form-group">
        <label for="start-time">Orario iniziale per essere ricontattato:</label>
        <input type="time" id="start-time" v-model="form.start_time" name="start-time" class="form-control mb-3" required>

        <label for="end-time" class="mt-3">Orario finale per essere ricontattato:</label>
        <input type="time" id="end-time" v-model="form.end_time" name="end-time" class="form-control" required>
      </div>
      <button type="submit" class="btn btn-primary" :disabled="isLoading">
        {{ isLoading ? 'Invio...' : 'Prenota un ricontatto' }}
      </button>
      <p v-if="statusMessage">{{ statusMessage }}</p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContactForm',
  data() {
    return {
      form: {
        name: '',
        surname: '',
        email: '',
        telephone: '',
        start_time: '',
        end_time: '' 
      },
      statusMessage: '',
      isLoading: false
    };
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      try {
        const response = await axios.post('http://localhost:8000/api/contact-submission', this.form, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        this.statusMessage = response.data.message;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.statusMessage = error.response && error.response.data.message ? error.response.data.message : 'Failed to submit data.';
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>

.contact-form {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
  input {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.form-control {
  background-color: var(--background-color);
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: var(--text-gray);
  display: block;
  font-weight: 500;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: var(--secondary-color);
}

@media (max-width: 600px) {
  .contact-form{
    padding: 0;
  }
}


</style>
