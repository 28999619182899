<template>
    <div>
        
        <h3>Newsletter</h3>

        <p class="pb-4">
            Iscriviti alla nostra newsletter per ricevere gli ultimi aggiornamenti e notizie.
        </p>

        <div>
            <NewsletterInput/>
        </div> 

        <div class="pt-4 pb-4">
            <div id="container_contact" >
                <div class="d-flex align-items-center">
                    <div class="pe-2">
                        <font-awesome-icon class="icon_radius" icon="fa-solid fa-phone" />
                    </div>
                    <div>
                        <div>
                            <a class="contact_link" href="tel:+06 18336600">06 18336600</a>
                        </div>
                        <div>
                            <p>Contatta i nostri esperti</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
    </div>
  </template>
  
  <script>

  import NewsletterInput from '../NewsletterInput.vue'

  export default {
    name: 'FooterFourth',
    components: {
      NewsletterInput
    }
  }
  </script>
  
  <style scoped>

  a{
    color: var(--footer-text);
    text-decoration: none;
    font-weight: 500;
  }
  
  ul {
      list-style-type: none;
      color: var(--footer-text);
      padding: 0;
  }
  
  h3{
    font-size: 20px;
    font-weight: 700;
    color: #FFFFFF;
    padding-bottom: 15px;
  }

    p{
        color: var(--footer-text);
        font-size: 15px;
        font-weight: 400;
        margin: 0;
    }

    .fa-phone{
        font-size: 30px;
        color: var(--primary-color);
        margin-right: 10px;
    }

    .contact_link{
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 700;
    }
  
  </style>