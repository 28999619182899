<template>

    <div id="topbar">
      <div class="d-flex w-75 align-items-center justify-content-between m-auto">
          <div class="d-flex">
              <div class="d-flex align-items-center pe-4">
                  <font-awesome-icon icon="fa-solid fa-location-dot" />
                  <a class="ps-3" target="_blank" href="https://www.google.it/maps/place/Via+Luigi+Luciani,+41,+00197+Roma+RM/@41.9221461,12.4780904,17z">
                    Via Luciani, 41 - 00197 Roma
                  </a>
              </div>

              <div class="d-flex align-items-center">
                  <font-awesome-icon icon="fa-solid fa-envelope" />
                  <a class="ps-2" href="mailto:info@changex.it">info@changex.it</a>
              </div> 
          </div>

          <div class="d-flex align-items-center">
              <span class="pe-5">FAQs</span>
              <div>
                  <ul>
                      <li><FontAwesomeIcon icon="fa-brands fa-x-twitter" /></li>
                      <li class="mx-4" ><FontAwesomeIcon icon="fa-brands fa-facebook" /></li>
                      <li class="me-4" ><FontAwesomeIcon icon="fa-brands fa-linkedin" /></li>
                      <li><FontAwesomeIcon icon="fa-brands fa-instagram" /></li>
                  </ul>
              </div>
          </div>
        </div>

    </div>

</template>
  
  <script>

  export default {
    name: 'HomeAbout',
  }
  </script>
  
  
  
  <style scoped>

  #topbar{
    padding: 7px 0px;
    background-color: var(--accent-color);
  }

  ul{
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  
    li{
      list-style-type: none;
      color: #FFFFFF;
    }

    .fa-envelope, .fa-location-dot{
    color: var(--secondary-color);
    font-size: 13px;
  }
  
  span, a{
    color: var(--footer-text);
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
  }

  @media (max-width: 1200px) {
  #topbar{
    display: none;
  }
}

  </style>