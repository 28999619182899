<template>
  <div class="carousel-container">
    <button @click="prevSlide" class="carousel-button prev" :disabled="currentSlide <= 0">‹</button>
    <div class="carousel-slide-container">
      <div class="carousel-slide" :style="{ transform: `translateX(-${currentSlide * slideWidth}%)` }">
        <InsuranceBox
          v-for="(box, index) in insboxes"
          :key="index"
          :id="box.id"
          :title="box.title"
          :image="getImagePath(box.image)"
          :icon="box.icon"
          class="carousel_item"
        />
      </div>
    </div>
    <button @click="nextSlide" class="carousel-button next" :disabled="currentSlide >= maxSlidePosition">›</button>
  </div>
</template>


<script>
import InsuranceBox from './InsuranceBox.vue';

export default {
  name: 'CustomCarousel',
  components: {
    InsuranceBox
  },
  props: {
    insboxes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentSlide: 0,
      slideWidth: 100
    };
  },
  computed: {
    totalSlides() {
      return this.insboxes.length;
    },
    maxSlidePosition() {
      return this.totalSlides - 1;
    }
  },
  methods: {
    nextSlide() {
      if (this.currentSlide < this.maxSlidePosition) {
        this.currentSlide++;
      }
    },
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      }
    },
    getImagePath(image) {
      return require(`../../assets/images/${image}`);
    },
    updateSlideWidth() {
      if (window.innerWidth < 768) {
        this.slideWidth = 100;
      } else {
        this.slideWidth = 30;
      }
    }
  },
  mounted() {
    this.updateSlideWidth(); 
    window.addEventListener('resize', this.updateSlideWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateSlideWidth);
  }
};
</script>




<style scoped>
.carousel-container {
  position: relative;
  width: 100%;
  overflow: visible;
  margin: 0 auto;
}

.carousel-slide-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.carousel-slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel_item {
  min-width: 30%;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: -10px;
}

.carousel-button.next {
  right: -25px;
  margin-left: 5px;
}

@media (max-width: 768px) {

  .carousel_item {
  min-width: 100%;
  }

  .carousel-button.prev {
    left: -25px;
  }
}

</style>