<template>
    <div>
      <router-link to="/"><img src="../../../assets/images/logo-changex.png" alt="Logo ChangeX" ></router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LogoHeader',
  }
  </script>
  
  
  <style scoped>
  
  img{
    height: 60px;
    width: auto;
    cursor:pointer;  
  }
  
  </style>