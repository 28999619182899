<template>

    <div class="container_newsletter">

        <div class="bg_square_shape_1 float-bob-x"></div>
        <div class="bg_square_shape_2 float-bob-y"></div>

        <div class="container_content">
            <font-awesome-icon class="d-flex align-items-start mt-1" icon="fa-solid fa-envelope-open-text" />
            <div class="text_newsletter">
                <p>
                    {{ subtitle }}
                </p>
                <h3>
                    {{ title }}
                </h3>
            </div>
        </div>

        <button type="button" class="btn"> <router-link :to="callToActionRoute">{{calltoaction}}</router-link></button>

        <div class="bg_square_shape_3 float-bob-x"></div>
        <div class="bg_square_shape_4 float-bob-y"></div>

    </div>

  </template>
  
  <script>
  export default {
    name: 'NewsletterBar',
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      calltoaction: {
        type: String,
        required: true
      },
      callToActionRoute: {
      type: String,
      required: true
    }
    }
  };
  </script>
  
  <style scoped>

.container_newsletter{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    background-color: var(--secondary-color);
    margin: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 57px 80px 57px;
    overflow: hidden;
    margin-top: 50px;
}

.container_content{
    display: flex;
    z-index: 2;
}

.text_newsletter{
  padding-left: 2rem;
}

h3{
    font-size: 36px;
    color: #FFFFFF;
    font-weight: 700;
    line-height: 47px;
}

p{
    font-size: 14px;
    color: #C2DFFF;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
}

  img{
    height: 14px;
    padding-bottom: 8px;
  }

  .fa-envelope-open-text{
    font-size: 65px;
    color: #FFFFFF;
  }

  .btn{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: var(--accent-color);
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    padding: 17px 40px 17px;
    transition: all 0.5s linear;
    overflow: hidden;
    z-index: 2;
    a{
        text-decoration: none;
        color: var(--accent-color);
    }
  }

    .btn:hover a{
        color: #FFFFFF;
    }

    .bg_square_shape_1{
        background-image: url('../assets/images/newsletter-shape-1.png');
        background-repeat: no-repeat;
        height: 300px;
        width: 300px;
        position: absolute;
        top: 50px;
        left: -90px;
        z-index: 1;
    }

    .bg_square_shape_2{
        background-image: url('../assets/images/newsletter-shape-1.png');
        background-repeat: no-repeat;
        height: 300px;
        width: 300px;
        position: absolute;
        top: -15px;
        left: -190px;
        z-index: 1;
    }

    .bg_square_shape_3{
        background-image: url('../assets/images/newsletter-shape-1.png');
        background-repeat: no-repeat;
        height: 300px;
        width: 300px;
        position: absolute;
        top: 50px;
        right: -90px;
        z-index: 1;
    }

    .bg_square_shape_4{
        background-image: url('../assets/images/newsletter-shape-1.png');
        background-repeat: no-repeat;
        height: 300px;
        width: 300px;
        position: absolute;
        top: -15px;
        right: -190px;
        z-index: 1;
    }

  @media (max-width: 780px) {

    .text_newsletter{
      padding-left: 0;
      padding-top: 15px;
    }
    .container_content{
      flex-direction: column;
    }
    
  }

  @media (max-width: 880px) {
    .bg_square_shape_1{
        top:220px
    }

    .bg_square_shape_3{
        top: -200px;
        right:-50px;
    }
  }




  @media (max-width: 980px) {
    .container_newsletter{
      flex-direction: column;
    }
    .btn{
        margin-top: 15px;
    }
  }

  </style>
  