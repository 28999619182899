<template>
  <div class="insurance_list_container">
    <ul class="p-0">
      <li v-for="(option, index) in insuranceOptions" :key="index">
        <router-link :to="{ name: 'detail', params: { id: option.id } }">
          {{ option.title }} 
          <span><font-awesome-icon icon="fa-solid fa-arrow-right-long" /></span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InsuranceList',
  data() {
    return {
      insuranceOptions: [
        { id: 1, title: 'Assicurazione Auto' },
        { id: 2, title: 'Assicurazione Vita' },
        { id: 3, title: 'Assicurazione Casa' },
        { id: 4, title: 'Assicurazione Sanitaria' },
        { id: 5, title: 'Assicurazione Cyber Security' },
        { id: 6, title: 'Assicurazione Immobili Commerciali' },
        { id: 7, title: 'Assicurazione Cani e Gatti' },
        { id: 8, title: 'Assicurazione Viaggio' },
        { id: 9, title: 'Assicurazione Legale' },
        { id: 10, title: 'Assicurazione Professionisti' },
        { id: 11, title: 'Assicurazione Eventi Atmosferici' },
        { id: 12, title: 'Assicurazione Sportiva' },
        { id: 13, title: 'Assicurazione Infortuni' },
        { id: 14, title: 'Cauzioni' }
      ]
    };
  }
};
</script>
  
  <style>

.insurance_list_container{
  background-color: var(--background-color);
  padding: 20px 20px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

li{
  list-style-type: none;
  padding-bottom: 1px
}

.insurance_list_container li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  background: linear-gradient(90deg, #f0f3f7 0%, white 100%);
  color: var(--text-gray);
  padding: 14px 30px 14px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-decoration: none;
}

.fa-arrow-right-long{
    color: var(--primary-color);
}

  </style>
  