<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary rounded">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse align-items-center" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item d-flex align-items-center">
            <router-link class="nav-link px-4" to="/" exact-active-class="active" exact>Home</router-link>
          </li>
          <li class="nav-item dropdown dropdown_container">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Soluzioni
            </a>
            <ul class="dropdown-menu multi-column">
                <li v-for="insurance in insurances" :key="insurance.id">
                  <router-link class="dropdown-item" :to="{ name: 'detail', params: { id: insurance.id } }">
                    {{ insurance.name }}
                  </router-link>
                </li>
            </ul>
          </li>
          <li class="nav-item d-flex align-items-center">
            <router-link class="nav-link" to="/assistenzasinistri">Assistenza Sinistri</router-link>
          </li>
          <li class="nav-item pe-4 d-flex align-items-center">
            <router-link class="nav-link" to="/contactus">Contatti</router-link>
          </li>
          <li class="nav-item rounded-end px-3 d-flex align-items-center">
            <router-link class="nav-link" to="/#form-preventivo">Preventivo</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
  export default {
    name: 'NavbarHeader',
    data() {
    return {
      insurances: [
        { id: 1, name: 'Assicurazione auto' },
        { id: 2, name: 'Assicurazione vita' },
        { id: 3, name: 'Assicurazione casa' },
        { id: 4, name: 'Assicurazione sanitaria' },
        { id: 5, name: 'Assicurazione cyber security' },
        { id: 6, name: 'Assicurazione immobili commerciali' },
        { id: 7, name: 'Assicurazione cane e gatto' },
        { id: 8, name: 'Assicurazione viaggio' },
        { id: 9, name: 'Assistenza legale' },
        { id: 10, name: 'Assicurazione professionisti' },
        { id: 11, name: 'Assicurazione eventi atmosferici' },
        { id: 12, name: 'Assicurazione sportiva' },
        { id: 13, name: 'Assicurazione Infortuni' },
        { id: 14, name: 'Cauzioni' }
      ]
    }
  }
  }
  </script>

<style scoped>

.container-fluid {
  --bs-gutter-x: 0;
}

a {
  padding: 12px;
}

nav a {
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 500;
}

nav a:hover {
  color: var(--secondary-color);
  transition: all 500ms linear;    
  transform: scale(1.1);
}

.navbar {
  padding: 0;
}

.nav-item:last-child {
  background-color: var(--secondary-color);
  a {
    color: #FFFFFF;
  }
}

.nav-item:last-child:hover {
  background-color: var(--accent-color);
}

.nav-item:last-child:hover a {
  color: var(--background-color); 
}

.dropdown_container {
  display: flex;
  align-items: center;
}

.navbar-nav .dropdown-menu {
  left: -90px;
}

.dropdown-menu.multi-column {
  column-count: 2; 
  column-gap: 20px; 
  width: 100%; 
}

.nav-item .dropdown-menu {
  min-width: 200px; 
  overflow-y: auto; 
  max-height: 50vh; 
}

@media (min-width: 990px) {
  .nav-item .dropdown-menu {
    min-width: 600px;
  }
}

@media (max-width: 768px) {
  .dropdown-menu.multi-column {
    column-count: unset;
    column-gap: 0;
    max-height: 300px; 
    overflow-y: auto;
  }
  /* .dropdown_container {
    flex-direction: column;
    width: 100%;
  } */
}
</style>

