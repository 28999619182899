<template>
  <footer>
    <div class="footer_class w-75">
      <div class="col">
        <FooterFirst/>
      </div>
      <div class="col">
        <FooterSecond/>
      </div>
      <div class="col">
        <FooterThird/>
      </div>
      <div class="col">
        <FooterFourth/>
      </div>
    </div>

    <div class="footer_copyright">
      <span class="text-center">© 2024 ChangeX All Rights Reserved.</span>
    </div>

  </footer>
</template>
  
  <script>

  import FooterFirst from './partials/footer/FooterFirst.vue'
  import FooterSecond from './partials/footer/FooterSecond.vue'
  import FooterThird from './partials/footer/FooterThird.vue'
  import FooterFourth from './partials/footer/FooterFourth.vue'

  export default {
    name: 'TotalHeader',
    components: {
      FooterFirst,
      FooterSecond,
      FooterThird,
      FooterFourth
    }
  }
  </script>
  
  
  
  <style scoped>
  footer {
    background-color: var(--accent-color);
  }
  
  .footer_class {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 100px 0 82px;
    margin: auto;
  }
  
  .col {
    flex: 1 1 22%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .footer_copyright {
    width: 75%;
    margin: auto;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 34px 0;
    color: var(--footer-text);
  }
  
  /* Media Queries for responsive design */
  @media (max-width: 992px) {
    .col {
      flex: 1 1 45%;
    }
  }
  
  @media (max-width: 768px) {
    .col {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }
  
    .footer_class {
      padding: 50px 0;
    }
  }
  </style>
  
  