<template>
  <div class="custom-container text-start">
    <img src="../assets/images/about-home-image.png" alt="Home About Image" class="custom-image mb-4">
    <div class="custom-content">
      <ArrowTitle :title="arrows.title"/>
      <h2>Siamo il <span style="color: var(--secondary-color);">broker</span> assicurativo più affidabile e tecnologico</h2>
      <p class="sub_title">Attraverso un infallibile sistema di intelligenza artificiale, coadiuvato da grandi professionisti, lavoreremo per trovare la migliore soluzione</p>
      <ul>
        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Più protezione</li>
        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Più velocità</li>
        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Più assistenza</li>
      </ul>
      <p>Mettici alla prova, richiedi un preventivo gratuito.</p>
      <button type="button" class="btn" @click.prevent="scrollToSection('form-preventivo')">Richiedi Preventivo</button>
    </div>
  </div>
</template>

  <script>

  import ArrowTitle from './partials/ArrowTitle.vue'

  export default {
    name: 'HomeAbout',
    components: {
      ArrowTitle,
    },
    data() {
    return {
      arrows:
        {
          title: 'ABOUT US',
        },
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  }
  </script>
  
  
  
  <style scoped>
  .custom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    position: relative;
    padding: 3rem;
  }
  
  .custom-image {
    width: 50%;
    max-width: 100%;
    height: auto;
  }
  
  .custom-container::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 826px;
    height: 627px;
    background-image: url('../assets/images/about-one-bg.png');
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
    mix-blend-mode: luminosity;
    opacity: 0.05;
    z-index: -1;
  }

  .custom-content {
    padding-left: 2rem;
    position: relative;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--accent-color);
  }
  
  p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-gray);
    padding: 15px 0px;
  }
  
  .sub_title {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--secondary-color);
  }
  
  ul {
    list-style: none;
    font-size: 1rem;
    padding: 0;
  }
  
  .fa-circle-check {
    color: var(--primary-color);
    margin-right: 0.5rem;
  }
  
  .btn {
    background-color: var(--secondary-color);
    padding: 0.85rem 2.5rem;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: darken(var(--secondary-color), 10%);
  }

  @media (max-width: 1199px) {
    .custom-container {
      flex-direction: column;
      text-align: center;
    }
  
    .custom-content {
      padding-left: 0;
      padding-top: 1rem;
    }
  
    .custom-image {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  
  @media (max-width: 767px) {
    h2 {
      font-size: 2rem;
    }
  
    p, .sub_title, ul {
      font-size: 1rem;
    }
    .custom-content {
    padding-left: 0;
  }
  }
  
  @media (max-width: 575px) {
    h2 {
      font-size: 1.75rem;
    }
  
    p, .sub_title, ul {
      font-size: 0.875rem;
    }
  
    .btn {
      padding: 0.75rem 2rem;
      font-size: 0.875rem;
    }
  }
  </style>
  