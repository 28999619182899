<template>
  
    <div class="hero_detail">
        <div class="hero_detail_bg">
          <div class="w-75 m-auto" >
            <p> CHANGEX / {{ insurancename }}</p>
            <h2>{{ insurancename }}</h2>
          </div>
        </div>
    </div>

</template>
  
    <script>
        export default {
            name: 'DetailHero',
            props: {
      insurancename: {
        type: String,
        required: true
      }
    }
}
    </script>
  
  
  <style scoped>

  .hero_detail_bg{
    background: linear-gradient(45deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%), url('../../assets/images/hero-image.jpg');
    background-size: cover;
    background-position: right; 
    background-repeat: no-repeat;
    background-color: var(--background-color); 
    padding: 115px 2rem;
  }

  h2{
    font-size: 40px;
    color: var(--accent-color);
    font-weight: 700;
    line-height: 52px;
  }

  p{
    color: var(--text-gray);
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }

@media (max-width: 480px) {

    .hero_detail_bg{
    padding: 1rem;
  }

  h2{
    font-size: 35px;
    line-height: 35px;
  }

}
  
  </style>