<template>
  <DetailHero :insurancename="insurance.name" />
  
  <div class="m-auto py-5 d-flex justify-content-around align-items-center container_contact">
    <div class="sidebar_container">

      <ArrowTitle
        :title="arrows.title"/>

      <h2 class="pb-4">Togliti ogni dubbio e contatta i nostri esperti</h2>

      <div id="container_contact" >
        <div class="d-flex align-items-center">
            <div class="pe-2">
                <font-awesome-icon class="icon_radius" icon="fa-solid fa-phone" />
            </div>
            <div>
                <div>
                    <a href="tel:+06 18336600">06 18336600</a>
                </div>
                <div>
                    <a href="mailto:info@changex.it">info@changex.it</a>
                </div>
            </div>
        </div>
        <div>
          <p>Via Luciani, 41 - 00197 Roma <br> Lazio, Italia</p>
        </div>
    </div>
    </div>

    <div class="contact_form">
      <ContactForm/>
    </div>

  </div>

</template>
  
  <script>
  import DetailHero from '../components/partials/DetailHero.vue';
  import ContactForm from '../components/ContactForm.vue';
  import ArrowTitle from '../components/partials/ArrowTitle.vue'

  
  
  export default {
    name: 'DetailPage',
    components: {
      DetailHero,
      ContactForm,
      ArrowTitle
    },
    data() {
      return {
        insurance: {
        name: 'Contattaci'
        },
        arrows:
        {
          title: 'CONTATTACI',
        },
      }
    }
}
</script>

<style scoped>

.container_contact{
    width: 75%;
  }

.contact_form{
  width: 100%;
}

.sidebar_container{
    padding-top: 30px;
    max-width: 40%;
  }

  h2{
    font-size: 45px;
    line-height: 55px;
    font-weight: 700;
  }
  
  .icon_radius{
      width: 50px;
      border: 2px solid var(--background-color);
      border-radius: 50%;
      padding: 20px 5px;
  }
  
  a{
    font-size: 20px;
    font-weight: 700;
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  p{
      color: var(--text-gray);
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      padding-top: 30px;
    }
    
    .fa-phone{
      color: var(--primary-color);
    }

    @media (max-width: 600px) {
      .container_contact{
        width: 90%;
        padding-bottom: 30px;
      }
    }
    
    @media (max-width: 980px) {
      .container_contact{
        flex-direction: column;     
        justify-content: center;
        width: 90%;
      }

      .sidebar_container{
        max-width: 75%;
        padding-bottom: 30px;
      }

    h2 {
        font-size: 36px;
        line-height: 46px;
    }

    .sidebar_container {
        max-width: 100%; 
        padding-top: 20px; 
    }
}

@media (max-width: 600px) {
    .container_contact {
        width: 100%;
        padding: 20px; 
        margin: auto;
    }

    a {
        font-size: 18px; 
    }

    p {
        font-size: 16px;
        line-height: 25px;
    }
}

</style>