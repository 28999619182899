<template>

    <HeroHome/>

    <div class="description_box m-auto py-5">
      <DescriptionBox
        v-for="(box, index) in boxes"
        :key="index"
        :id="box.id"
        :title="box.title"
        :content="box.content"
        :icon="box.icon"
        class="col"
      />
    </div>

    <HomeAbout/>
    <InsuranceSolutions/>
    <StatisticsRow/>
    <HomeForm/>
    <NewsletterBar :title="newsletter.title" :subtitle="newsletter.subtitle" :calltoaction="newsletter.calltoaction" :call-to-action-route="newsletter.calltoactionroute"/>

  </template>
  
  <script>
  
  import HeroHome from '../components/HeroHome.vue';
  import DescriptionBox from '../components/DescriptionBox.vue';
  import HomeAbout from '../components/HomeAbout.vue';
  import InsuranceSolutions from '../components/InsuranceSolutions.vue';
  import StatisticsRow from '../components/StatisticsRow.vue';
  import HomeForm from '../components/HomeForm.vue';
  import NewsletterBar from '../components/NewsletterBar.vue'
  
  export default {
  name: 'TestPage',
  components: {
    HeroHome,
    DescriptionBox,
    HomeAbout,
    InsuranceSolutions,
    StatisticsRow,
    HomeForm,
    NewsletterBar
  },
  data() {
    return {
      boxes: [
        {
          title: 'Progetto assicurativo personalizzato',
          content: 'Pensato appositamente per te, con una copertura completa e flessibile, studiata attentamente per garantirti tranquillità in ogni momento della tua vita, della tua famiglia e della tua azienda',
          icon: ['fa-solid', 'user-shield']
        },
        {
          title: 'Soluzione assicurativa più vantaggiosa',
          content: 'Progettata appositamente per adattarsi al tuo budget, garantendo la massima copertura senza compromettere la tua situazione finanziaria',
          icon: ['fa-solid', 'fa-hand-holding-dollar']
        },
        {
          title: 'Copertura rapida e veloce',
          content: 'La soluzione ideale per coloro che cercano una copertura immediata e senza intoppi. Grazie a processi semplificati ed una risposta tempestiva',
          icon: ['fa-solid', 'house-circle-check']
        }
      ],
      newsletter:
        {
          title: 'Inviaci la tua richiesta',
          subtitle: 'Assistenza',
          calltoaction: 'Contattaci',
          calltoactionroute: '/contactus'
        }   
    };
  }
};
  </script>

<style scoped>

.description_box{
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap
}

@media (max-width: 1200px) {
    .description_box {
      width: 90%;
    }
  }

@media (max-width: 998px) {
    .description_box {
      flex-direction: column;
    }
  }

</style>