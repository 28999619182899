<template>
    <div class="box">
      <div class="d-flex align-items-center">
        <font-awesome-icon :icon="icon" class="icon" />
        <p>{{ number }}</p>
      </div>
      <h2>{{ content }}</h2>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InsuranceBox',
    props: {
      content: {
        type: String,
        required: true
      },
      number: {
        type: String,
        required: true
      },
      icon: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>

  h2{
    font-size: 24px;
    font-weight: 400;
    color: #c2dfff;
  }
  
  p{
    font-size: 36px;
    color: #ffffff;
    font-weight: 700;
    line-height: 26px;
  }

  .icon{
    font-size: 45px;
    color: var(--primary-color);
    padding-right: 10px; 
    padding-bottom: 15px;
  }

  </style>
  