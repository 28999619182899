<template>
    <div class="container_total">

      <div class="container_content">

        <div class="container_image">
          
        </div>

        <div class="container_form_login">
          <div class="container">
            <div class="login_form">

              <h2>Benvenuto!</h2>
              <p>Accedi al portale di gestione delle tue polizze</p>

              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="username">Username:</label>
                  <input type="text" id="username" v-model="form.username" class="form-control" required>
                </div>
                <div class="form-group">
                  <label for="password">Password:</label>
                  <input type="password" id="password" v-model="form.password" class="form-control" required>
                </div>
                <button type="submit" class="btn btn-primary" :disabled="isLoading">
                  {{ isLoading ? 'Logging in...' : 'Login' }}
                </button>
                <p v-if="statusMessage">{{ statusMessage }}</p>
              </form>
            </div>
          </div>
        </div>
        

      </div>

      <div class="center_logo">
        <img src="../assets/images/logo-x-white.png" alt="">
      </div>

    </div>

  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'LoginForm',
    data() {
      return {
        form: {
          username: '',
          password: ''
        },
        statusMessage: '',
        isLoading: false
      };
    },
    methods: {
      async submitForm() {
        this.isLoading = true;
        try {
          const response = await axios.post('https://changex.it/api/login', this.form);
          this.statusMessage = response.data.message;
          this.isLoading = false;
        } catch (error) {
          console.error(error);
          this.statusMessage = error.response && error.response.data.message ? error.response.data.message : 'Failed to login.';
          this.isLoading = false;
        }
      }
    }
  };
  </script>
  
  <style scoped>

  .container_total{
    height: 100vh;
    background-color: var(--background-color);
    padding: 80px 0px;
    position: relative;
  }

  .container_content{
    width: 75%;
    height: 100%;
    margin: auto;
    display: flex;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .container_image{
    width: 50%;
    height: 100%;
    background-image: url('../assets/images/insurance-login-2.jpg');
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
  }

  img{
    width: 100%;
    height: 100%;
  }

  .container_form_login{
      width: 50%;
      background-color: #FFFFFF;
    }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .login_form {
    width: 90%;
    padding: 40px 20px 40px 40px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group {
      margin-bottom: 15px;
      input{
        padding-left: 30px;
        padding-right: 30px;
      }
    }

  .form-control{
    background-color: var(--background-color);
    height: 60px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    color: var(--text-gray);
    display: block;
    font-weight: 500;
    }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0e2a47;
  }

  h2{
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }

  p{
    color: var(--secondary-color);
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  .center_logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-color);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    box-shadow: rgba(0, 0, 0, 0.42) 0px 0px 39px 6px;
    padding: 10px;
  }

  @media (max-width: 768px) {

    .container_total{
    height: 100%;
  }

  .container_content {
    flex-direction: column; 
    height: auto;
    width: 85%;
  }

  .login_form{
    padding: 40px 20px;
  }

  .container_image{
  width: 100%;
  height: 200px; 
  background-image: url('../assets/images/insurance-login-2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  }

  .center_logo{
    top: 280px;
  }

  .container_form_login{
    width: 100%;
    background-color: #FFFFFF;
  }


  .container_image, .container_form_login {
    width: 100%;
  }
  }

  </style>
  