<template>
  <div class="container py-3 my-5">
        <h1>Cookie Policy</h1>
        <p><strong>Ultimo aggiornamento:</strong> [31/05/2024]</p>

        <h2>Introduzione</h2>
        <p>Benvenuti su ChangeX! Utilizziamo cookie e tecnologie simili per migliorare la tua esperienza sul nostro sito. Questa Cookie Policy spiega cosa sono i cookie, come li utilizziamo e come puoi gestirli.</p>

        <h2>Cosa sono i cookie?</h2>
        <p>I cookie sono piccoli file di testo che vengono memorizzati sul tuo dispositivo quando visiti un sito web. I cookie possono essere utilizzati per vari scopi, tra cui migliorare la navigazione, analizzare il traffico e personalizzare i contenuti.</p>

        <h2>Tipi di cookie utilizzati</h2>
        <ul>
            <li><strong>Cookie Necessari:</strong> Questi cookie sono essenziali per il funzionamento del sito. Senza questi cookie, alcuni servizi potrebbero non funzionare correttamente.</li>
            <li><strong>Cookie di Prestazione:</strong> Utilizziamo questi cookie per raccogliere informazioni su come i visitatori utilizzano il nostro sito. Questi dati ci aiutano a migliorare le funzionalità del sito.</li>
            <li><strong>Cookie Funzionali:</strong> Questi cookie permettono al sito di ricordare le scelte che fai (come la lingua o la regione) e fornire funzionalità avanzate e personalizzate.</li>
            <li><strong>Cookie di Targeting/Pubblicità:</strong> Questi cookie sono utilizzati per mostrarti annunci pubblicitari rilevanti per te e i tuoi interessi. Possono anche limitare il numero di volte in cui vedi un annuncio.</li>
        </ul>

        <h2>Durata dei cookie</h2>
        <ul>
            <li><strong>Cookie di sessione:</strong> vengono eliminati quando chiudi il browser.</li>
            <li><strong>Cookie persistenti:</strong> rimangono sul tuo dispositivo per un periodo di tempo definito o fino a quando non li elimini manualmente.</li>
        </ul>

        <h2>Gestione dei cookie</h2>
        <p>Puoi gestire o disattivare i cookie tramite le impostazioni del tuo browser. Tieni presente che la disattivazione dei cookie potrebbe influire sulla tua esperienza utente sul nostro sito.</p>

        <h2>Cookie di terze parti</h2>
        <p>Utilizziamo anche cookie di terze parti, ad esempio, Google Analytics per analizzare il traffico sul nostro sito. Questi cookie sono gestiti da terze parti e ti invitiamo a leggere le loro politiche sulla privacy per ulteriori informazioni.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CookiePolicy',
  }
  </script>
  
  
  <style scoped>
  
  .container {
            max-width: 800px;
            margin: auto;
            padding: 20px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1, h2, h3 {
            color: #333;
        }
        p {
            margin: 1em 0;
        }
        ul {
            margin: 1em 0;
            padding-left: 20px;
        }
        li {
            margin-bottom: 0.5em;
        }
  
  </style>