<template>

    <div id="container_contact" >
        <div class="d-flex align-items-center">
            <div class="pe-2">
                <font-awesome-icon class="icon_radius" icon="fa-solid fa-phone" />
            </div>
            <div>
                <div>
                    <a href="tel:+06 18336600">06 18336600</a>
                </div>
                <div>
                    <p>Contatta i nostri esperti</p>
                </div>
            </div>
        </div>
    </div>
    
  </template>

<script>
export default {
  name: 'ContactHeader',
}
</script>

<style scoped>

    .icon_radius{
        width: 50px;
        border: 2px solid var(--background-color);
        border-radius: 50%;
        padding: 20px 5px;
    }

    a{
        text-decoration: none;
        color: #000000;
        font-weight: bold;
    }

    p{
        color: var(--text-gray);
        margin-bottom: 0px;
    }

    .fa-phone{
        color: var(--primary-color);
    }

@media (max-width: 1200px) {
  #container_contact{
    display: none;
  }
}


</style>