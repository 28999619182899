<template>
    <header class="my-2 sticky-header">

      <div class="header_class">
        <div>
          <LogoHeader/>
        </div>
        <div>
          <NavbarHeader/>
        </div>
        <div>
          <ContactHeader/>
        </div>
      </div>
  
    </header>
    
  </template>
  
  <script>

  import LogoHeader from './partials/header/LogoHeader.vue'
  import NavbarHeader from './partials/header/NavbarHeader.vue'
  import ContactHeader from './partials/header/ContactHeader.vue'

  export default {
    name: 'TotalHeader',
    components: {
      LogoHeader,
      NavbarHeader,
      ContactHeader
    }
  }
  </script>
  
  
  
  <style scoped>
  
    .header_class{
      display: flex;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0px;
      width: 75%;
    }

    .sticky-header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: white; 
      z-index: 1000; 
      width: 100%;
    }

    @media (max-width: 768px) {
      .header_class {
      width: 100%;
    }
}
  
  </style>