<template>
    <div class="container py-3 my-5">
        <h1>Privacy Policy</h1>
        <p><strong>Ultimo aggiornamento:</strong> [31/05/2024]</p>

        <h2>Introduzione</h2>
        <p>ChangeX si impegna a proteggere la tua privacy. Questa Privacy Policy spiega come raccogliamo, utilizziamo, divulghiamo e proteggiamo i tuoi dati personali.</p>

        <h2>Tipi di dati raccolti</h2>
        <ul>
            <li><strong>Dati Personali:</strong> Nome, indirizzo email, numero di telefono, informazioni di pagamento.</li>
            <li><strong>Dati Tecnici:</strong> Indirizzo IP, tipo di browser, informazioni sul dispositivo.</li>
            <li><strong>Dati di Utilizzo:</strong> Pagine visitate, tempo trascorso sul sito, clic e altre informazioni di navigazione.</li>
        </ul>

        <h2>Modalità di raccolta dei dati</h2>
        <ul>
            <li><strong>Moduli di contatto:</strong> Quando compili un modulo di contatto o ti registri sul nostro sito.</li>
            <li><strong>Cookie:</strong> Tramite i cookie e tecnologie simili.</li>
            <li><strong>Analisi del sito:</strong> Utilizzando strumenti di analisi come Google Analytics.</li>
        </ul>

        <h2>Finalità della raccolta dei dati</h2>
        <ul>
            <li><strong>Miglioramento del servizio:</strong> Per migliorare la tua esperienza utente e i nostri servizi.</li>
            <li><strong>Marketing:</strong> Per inviarti comunicazioni promozionali pertinenti.</li>
            <li><strong>Analisi:</strong> Per comprendere meglio come viene utilizzato il nostro sito e migliorarlo.</li>
        </ul>

        <h2>Base giuridica del trattamento</h2>
        <p>Trattiamo i tuoi dati personali sulla base del tuo consenso, dell'esecuzione di un contratto, del rispetto di obblighi legali e del nostro legittimo interesse a migliorare i nostri servizi.</p>

        <h2>Condivisione dei dati</h2>
        <ul>
            <li><strong>Partner commerciali:</strong> Condividiamo i dati con partner che ci aiutano a fornire e migliorare i nostri servizi.</li>
            <li><strong>Fornitori di servizi:</strong> Utilizziamo fornitori di servizi terzi per attività come l'elaborazione dei pagamenti e l'analisi dei dati.</li>
            <li><strong>Enti governativi:</strong> Potremmo divulgare i tuoi dati a enti governativi se richiesto dalla legge.</li>
        </ul>

        <h2>Sicurezza dei dati</h2>
        <p>Adottiamo misure tecniche e organizzative adeguate per proteggere i tuoi dati personali da accessi non autorizzati, perdite, distruzioni o divulgazioni.</p>

        <h2>I tuoi diritti</h2>
        <p>Hai il diritto di accedere, rettificare, cancellare e limitare l'uso dei tuoi dati personali. Puoi anche opporti al trattamento dei tuoi dati e richiedere la portabilità dei dati.</p>

        <h2>Contatti</h2>
        <p>Per qualsiasi domanda o richiesta relativa alla Privacy Policy, contattaci all'indirizzo email: <a href="mailto:privacy@changex.com">privacy@changex.com</a>.</p>
    </div>
      
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy',
  }
  </script>
  
  
  <style scoped>

.container {
            max-width: 800px;
            margin: auto;
            padding: 20px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1, h2, h3 {
            color: #333;
        }
        p {
            margin: 1em 0;
        }
        ul {
            margin: 1em 0;
            padding-left: 20px;
        }
        li {
            margin-bottom: 0.5em;
        }
  
  </style>