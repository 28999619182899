<template>

  <div class="d-flex flex-wrap justify-content-between statistic_row px-5">
    <div class="statistic_image_top float-bob-y"></div>
    <StatisticBox 
      class="col-12 col-sm-6 col-md-4 col-lg-3 stats_boxes"
      v-for="(box, index) in insboxes"
      :key="index"
      :content="box.content"
      :number="box.number"
      :icon="box.icon"
    />
    <div class="statistic_image_bottom float-bob-y"></div>
  </div>

</template>


<script>
import StatisticBox from './partials/StatisticBox.vue';

export default {
name: 'InsuranceSolutions',
components: {
  StatisticBox,
},
data() {
  return {
    insboxes: [
      {
        content: 'Giorni per definire i tuoi sinistri',
        number: '2',
        icon: ['fa-solid', 'fa-file-signature'] 
      },
      {
        content: 'Professionisti qualificati',
        number: '40+',
        icon: ['fa-solid', 'fa-people-group'] 
      },
      {
        content: 'Clienti soddisfatti',
        number: '100%',
        icon: ['fa-solid', 'fa-user-shield'] 
      },
      {
        content: 'Di successo dopo il primo contatto',
        number: '99%',
        icon: ['fa-solid', 'fa-clipboard-check'] 
      }
    ]
  };
}
};
</script>

<style scoped>
.statistic_row {
  background-color: var(--secondary-color);
  padding: 60px 30px 40px 30px;
  position: relative; 
}

.statistic_image_top {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100px;
  opacity: 0.2;
  background-image: url('../assets/images/statistic-background-shape-top.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.statistic_image_bottom {
  position: absolute;
  bottom: -60px;
  right: 0;
  width: 370px;
  height: 300px;
  opacity: 0.2;
  background-image: url('../assets/images/statistic-background-shape-bottom.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF; 
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; 
  z-index: 2; 
}

/* @media (max-width: 575px) {
  .stats_boxes:first-child {
    margin-top: 0px;
  }
  .stats_boxes {
    margin-top: 20px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .stats_boxes:nth-child(3), .stats_boxes:last-child {
    margin-top: 20px; 
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .stats_boxes:last-child {
    margin-top: 20px;
  }
} */

</style>
