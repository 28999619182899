<template>
    <div v-if="!isAuthenticated" class="password-overlay">
      <div class="password-modal">
        <h3>Inserisci la password per accedere</h3>
        <div class="form-group mt-3">
          <input 
            type="password" 
            v-model="password"
            class="form-control" 
            placeholder="Password"
            @keyup.enter="checkPassword"
          >
        </div>
        <button @click="checkPassword" class="btn btn-primary mt-3">
          Accedi
        </button>
        <p v-if="error" class="text-danger mt-2">{{ error }}</p>
      </div>
    </div>
   
    <TopBar v-if="isAuthenticated"/>
    <TotalHeader v-if="isAuthenticated"/>
    <router-view v-if="isAuthenticated"/>
    <TotalFooter v-if="isAuthenticated"/>
   </template>
   
   <script>
   import TotalHeader from "./components/TotalHeader.vue";
   import TotalFooter from "./components/TotalFooter.vue";
   import TopBar from "./components/TopBar.vue";
      
   export default {
    name: 'App',
    components: {
      TopBar,
      TotalHeader,
      TotalFooter,
    },
    data() {
      return {
        isAuthenticated: false,
        password: '',
        error: ''
      }
    },
    created() {
      // Controlla se già autenticato
      this.isAuthenticated = localStorage.getItem('isAuthenticated') === 'true'
    },
    methods: {
      checkPassword() {
        if (this.password === 'changex2024') {
          this.isAuthenticated = true
          localStorage.setItem('isAuthenticated', 'true')
          this.error = ''
        } else {
          this.error = 'Password non corretta'
        }
      }
    }
   }
   </script>
    
   <style lang="scss">
   #app {
    font-family: 'DM Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: start;
    color: #2c3e50;
   }
   
   nav {
    padding: 30px;
   }
   
   .btn:hover{
    transition: all 500ms linear;
    background-color: var(--accent-color)!important;
    color: #FFFFFF!important;
   }
   
   .password-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
   }
   
   .password-modal {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
   }
   
   .form-group {
    margin: 1rem 0;
   }
   
   .form-control {
    width: 100%;
    padding: 0.5rem;
   }
   </style>