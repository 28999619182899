<template>
  <div class="container_form" id="form-preventivo">
    <div class="form-content">
      <div class="img_container">
        <img class="img-fluid" src="../assets/images/get-insurance-woman.png" alt="Woman form Image">
      </div>
      <div class="text_container">
        <ArrowTitle :title="arrows.title"/>
        <h2>Contattaci per avere un <span style="color: var(--secondary-color);">preventivo</span> gratuito</h2>
        <InsuranceForm/>
      </div>
    </div>
  </div>
</template>


  
  <script>

  import InsuranceForm from './partials/InsuranceForm.vue'
  import ArrowTitle from './partials/ArrowTitle.vue'

  export default {
    name: 'HomeAbout',
    components: {
      InsuranceForm,
      ArrowTitle
    },
    data() {
    return {
      arrows:
        {
          title: 'FACILE E VELOCE',
        },
    };
  }
  }
  </script>
  
  
  
  <style scoped>
  .container_form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px 20px 0px 20px;
    overflow: hidden;
  }
  
  .container_form::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/background-form.png');
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: -1;
  }
  
  .form-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    z-index: 1;
  }
  
  .img_container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .text_container {
    flex: 1;
    padding-left: 2rem;
  }
  
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--accent-color);
  }
  
  .btn {
    background-color: var(--secondary-color);
    padding: 17px 40px;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: darken(var(--secondary-color), 10%);
  }
  
  @media (max-width: 1200px) {
    .form-content {
      flex-direction: column;
    }
  
    .text_container {
      padding-left: 0;
      padding-top: 1rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    .btn {
      padding: 15px 30px;
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 1024px) {
    .img_container {
      display: none;
    }
  }
  
  @media (max-width: 576px) {
    h2 {
      font-size: 1.5rem;
    }
  
    .btn {
      padding: 12px 25px;
      font-size: 0.75rem;
    }
  }
  </style>
  