<template>

    <div class="d-flex flex-column justify-content-center contact_container">
        <div class="icon_radius">
            <font-awesome-icon class="" icon="fa-solid fa-phone-volume" />
        </div>

        <div>
            <h3>
                Parla con un nostro agente assicurativo
            </h3>
        </div>
        <div class="pt-4">
            <div>
                <a href="tel:+06 18336600">06 18336600</a>
            </div>
            <div>
                <p>I nostri esperti</p>
            </div>
        </div>
    </div>
    
  </template>

<script>
export default {
  name: 'DetailContact',
}
</script>

<style scoped>

.contact_container{
    position: relative;
    padding: 50px 50px 46px;
    text-align: center;
    z-index: 1;
    background-color: var(--accent-color);
    border-radius: 10px;
    
}

    .icon_radius{
    height: 85px;
    width: 85px;
    background-color: var(--primary-color);
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 22px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    }

    h3{
        color: #FFFFFF;
        font-size: 26px;
        color: FFFFFF;
        line-height: 34px;
        font-weight: 700;
        letter-spacing: -0.04em;
    }

    a{
        text-decoration: none;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 700;
    }

    p{
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
    }

    .fa-phone-volume{
        color: #FFFFFF;
        font-size: 37px;
    }


</style>