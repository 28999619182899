<template>
    <div>
        
      <ul>
        <li><h3>Link Utili</h3></li>
        <li class="pb-2"><router-link to="/privacyPolicy">Privacy Policy</router-link></li>
        <li><router-link to="/CookiePolicy">Cookie Policy</router-link></li>
      </ul>
  
    </div>
  </template>
  
  <script>
  export default {
    name: 'FooterThird',
  }
  </script>
  
  <style scoped>

  a{
    color: var(--footer-text);
    text-decoration: none;
    font-weight: 500;
  }
  
  ul {
      list-style-type: none;
      color: var(--footer-text);
      padding: 0;
  }
  
  h3{
    font-size: 20px;
    font-weight: 700;
    color: #FFFFFF;
    padding-bottom: 15px;
  }
  
  </style>