<template>
    <div class="box text-start">
      <div class="background-icon">
        <font-awesome-icon :icon="icon" class="icon box-image"  />
      </div>
      <h2>{{ title }}</h2>
      <p>{{ content }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DescriptionBox',
    props: {
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      },
      icon: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>

 .box {
  padding: 132px 50px 32px;
  margin: 10px;
  position: relative;
  border-radius: 25px;
  min-height: 420px;
  overflow: hidden;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

.box:hover {
  background-color: var(--accent-color);
      transition: all 500ms linear;
  h2{
    color: #FFFFFF;
  }
  .icon{
    transition: all 500ms linear;
    transform: scale(0.9);
  }
}

.background-icon{
  position: absolute;
    left: -40px;
    top: -90px;
    border-radius: 50%;
    background-image: linear-gradient(0deg, var(--insur-base, #015fc9) 0%, var(--insur-primary, #0ce0ff) 100%);
    width: 197px;
    height: 197px;
}

.box-image {
  position: relative;
  top: 107px;
  left: 81px;
  width: 65px;
  height: 65px;
  object-fit: cover;
  color: #FFFFFF;
}

h2{
  font-size: 24px;
  font-weight: 700;
  color: var(--accent-color)
}

p{
  font-size: 16px;
  font-weight: 400;
  color: var(--footer-text);
}
  </style>
  