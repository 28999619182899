<template>

    <div class="box">

      <div class="box-image">
          <img :src="image" alt="Box insurance image" class=""/>
      </div>
      <div class="text_container d-flex align-items-center">
        <div class="icon_container">
          <span class="icon_box"><font-awesome-icon :icon="icon" class="icon" /></span>
        </div>
          <router-link :to="{ name: 'detail', params: { id: id } }">
            <h3>{{ title }}</h3>
          </router-link>
      </div>
      
    </div>

  </template>
  
  <script>
  export default {
    name: 'InsuranceBox',
    props: {
      id: {
        type: Number,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      image: {
        type: String,
        required: true
      },
      icon: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
.box {
  position: relative;
  margin-bottom: 30px;
  width: 90%;
  padding: 0px 15px;
}

.box-image {
  position: relative;
}

.box-image img {
  display: block;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  transition: all 0.5s ease;
}

.box-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s ease;
  border-radius: 10px 10px 0px 0px;
}

.box:hover .box-image::before {
  opacity: 1;
}

.icon_box {
  position: relative;
  display: inline-block;
  font-size: 36px;
  color: var(--secondary-color);
  transition: all 500ms ease;
  transition-delay: 0.1s;
  transform: scale(1);
}

.box:hover .icon_box {
  color: #FFFFFF;
}

.box:hover .icon_container {
  background-color: var(--secondary-color);
}

.box:hover h3 {
  color: var(--secondary-color);
}

.icon_container {
  position: absolute;
  top: -40px;
  right: 20px;
  height: 60px;
  width: 60px;
  background-color: white;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  z-index: 3;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--accent-color);
}

.text_container {
  position: relative;
  display: block;
  background-color: white;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 37px 30px 28px;
  min-height: 145px;
}

@media (max-width: 768px) {

  .box {
  width: 100%;
  padding: 0;
  }

}

</style>
