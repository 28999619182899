<template>
    <div class="newsletter_signup">
      <form @submit.prevent="submitNewsletter" class="input-wrapper">
        <input
          v-model="email"
          type="email"
          placeholder="Inserisci la tua mail"
          required
        />
        <button type="submit" class="input-icon">
            <font-awesome-icon icon="fa-solid fa-paper-plane" />
        </button>
      </form>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  
  <script>

import axios from 'axios';

  export default {
    name: 'NewsletterInput',
    data() {
      return {
        email: '',
        message: ''
      };
    },
    methods: {
      async submitNewsletter() {
        try {
          const response = await axios.post('http://127.0.0.1:8000/api/subscribe', { email: this.email });
          this.message = response.data.message;
          this.email = '';
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errors) {
            this.message = error.response.data.errors.email[0];
          } else {
            this.message = 'Failed to subscribe. Please try again later.';
          }
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .newsletter_signup .input-wrapper {
    position: relative;
    display: flex;
  }
  
  .newsletter_signup input {
    padding: 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    width: 100%;
    background-color: #111d32;
    font-size: 14px;
    color: #FFFFFF;
  }
  
  .newsletter_signup .input-icon {
    position: absolute;
    right: 8px;
    top: 20px;
    margin: 0;
    border: none;
    background: none;
    color: #FFFFFF;
    cursor: pointer;
  }
  
  .newsletter_signup .input-icon:hover {
    color: var(--secondary-color);
  }
  
  .newsletter_signup p {
    margin-top: 10px;
  }
  </style>
  
  