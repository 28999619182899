import { createRouter, createWebHashHistory } from 'vue-router'
import HomeChangex from '../views/HomeChangex.vue'
import DetailPage from '../views/DetailPage.vue'
import AssistenzaSinistri from '../views/AssistenzaSinistri.vue'
import ContactUs from '../views/ContactUs.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import CookiePolicy from '../views/CookiePolicy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeChangex
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: DetailPage,
    props: true
  },
  {
    path: '/Assistenzasinistri',
    name: 'Assistenzasinistri',
    component: AssistenzaSinistri
  },
  {
    path: '/contactus',
    name: 'contact',
    component: ContactUs,
    props: true
  },
  {
    path: '/privacyPolicy',
    name: 'privacypolicy',
    component: PrivacyPolicy
  },
  {
    path: '/cookiePolicy',
    name: 'cookiepolicy',
    component: CookiePolicy
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { left: 0, top: 0 };
    }
  }
})

export default router
