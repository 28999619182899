<template>
    <div class="footer_second_wrapper">
        
    <h3>Contatti</h3>

    <div>
        <font-awesome-icon icon="fa-solid fa-envelope" />
        <a class="ps-3" href="mailto:info@changex.it">info@changex.it</a>
    </div> 

    <div class="pt-2 pb-4">
        <font-awesome-icon icon="fa-solid fa-location-dot" />
        <a class="ps-3" target="_blank" href="https://www.google.it/maps/place/Via+Luigi+Luciani,+41,+00197+Roma+RM/@41.9221461,12.4780904,17z">
          Via Luciani, 41 - 00197 Roma
        </a>
    </div>

    <h3>Orari di apertura</h3>

    <p>Lun – Sab: 8:00 am to 6:00 pm <br> Domenica: Chiusi</p>
    
    </div>
  </template>
  
  <script>
  export default {
    name: 'FooterSecond',
  }
  </script>
  
  <style scoped>

.footer_second_wrapper{
  line-height: 26px;
}

  h3{
    font-size: 20px;
    font-weight: 700;
    color: #FFFFFF;
    padding-bottom: 20px;
  }

  .fa-envelope, .fa-location-dot{
    color: var(--secondary-color);
  }
  
  p, a{
    color: var(--footer-text);
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
    }
  
  </style>