<template>
    <div class="d-flex align-items-center">
      <img src="../../assets/images/title-arrow-left.png" alt="arrow title left">
      <h2 class="mx-2">{{ title }}</h2>
      <img src="../../assets/images/title-arrow-right.png" alt="arrow title right">
    </div>
  </template>
  
  <script>
  export default {
    name: 'ArrowTitle',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
h2{
  font-size:16px;
  font-weight: 700;
  line-height: 26px;
  color: var(--accent-color)
}

  img{
    height: 14px;
    padding-bottom: 8px;
  }

  </style>
  