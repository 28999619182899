import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/variables.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, fab);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);


createApp(App)
    .component("FontAwesomeIcon", FontAwesomeIcon)
    .use(router)
    .mount("#app");
