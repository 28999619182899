<template>
    <DetailHero :insurancename="insurance.name" />
  
    <div class="w-75 m-auto py-5 d-flex justify-content-around">

      <div class="sidebar_container">
        <InsuranceList />
        <DetailContact />
      </div>
  
      <div>
        <div v-for="(section, index) in insurance.sections" :key="index" class="pb-4">
          <h2 class="mb-4">{{ section.title }}</h2>
          <p v-html="section.description"></p>
          <div v-if="section.vantaggi && section.vantaggi.length > 0">
            <h3 class="py-3">Vantaggi</h3>
            <ul>
              <li v-for="(vantaggio, vantaggioIndex) in section.vantaggi" :key="vantaggioIndex">
                <FontAwesomeIcon icon="fa-solid fa-circle-check" /> {{ vantaggio }}
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h3 class="form_title text-center">Richiedi un <span style="color: var(--secondary-color);">preventivo</span> gratuito</h3>
          <InsuranceForm :default-insurance-type="{value: insurance.id, text: insurance.name}" />
        </div>

        <!-- <div class="mobile_contact">
          <DetailContact/>
        </div> -->
      </div>

    </div>

    <NewsletterBar :title="newsletter.title" :subtitle="newsletter.subtitle" :calltoaction="newsletter.calltoaction" :call-to-action-route="newsletter.calltoactionroute"/>

    
  </template>
  
  <script>
  import DetailHero from '../components/partials/DetailHero.vue';
  import DetailContact from '../components/partials/DetailContact.vue';
  import InsuranceList from '../components/partials/InsuranceList.vue';
  import InsuranceForm from '../components/partials/InsuranceForm.vue';
  import NewsletterBar from '@/components/NewsletterBar.vue';
  
  export default {
    name: 'DetailPage',
    components: {
      DetailHero,
      DetailContact,
      InsuranceList,
      InsuranceForm,
      NewsletterBar
    },
    props: ['id'],
    data() {
      return {
        insurance: {},
        newsletter:
        {
          title: 'Inviaci la tua richiesta',
          subtitle: 'Assistenza',
          calltoaction: 'Contattaci',
          calltoactionroute: '/contactus'
        }  
      };
    },
    created() {
    this.fetchInsuranceDetails(this.id);
  },
  watch: {
    id(newId) {
      this.fetchInsuranceDetails(newId);
    }
  },
    methods: {
      fetchInsuranceDetails(id) {
        const items = [
          {
            id: 1,
            name: 'Assicurazione auto',
            sections: [
              {
                title: 'RCA (Responsabilità Civile Autoveicoli)',
                description: 'La sua funzione è tutelare<br> il patrimonio dell’assicurato per i danni involontariamente causati a terzi e/o subiti durante la circolazione stradale. La varietà delle garanzie assicurabili con la CVT (Corpo Veicoli Terrestri) vanno dal furto e incendio, alla Kasko, infortuni del conducente, Assicurazione sospensione patente di guida, Eventi atmosferici e Atti vandalici e consente una protezione molto elevata del proprio veicolo e del proprio patrimonio.',
                vantaggi: [
                  'Protezione completa del veicolo',
                  'Copertura furto e incendio',
                  'Assicurazione infortuni del conducente',
                  'Assistenza stradale 24/7'
                ]
              }
            ]
          },
          {
            id: 2,
            name: 'Assicurazione vita',
            sections: [
              {
                title: 'VITA CAPITALIZZAZIONE',
                description: 'Questa polizza interviene nel caso in cui il contraente affidi una certa somma, che si intende investire o conservare a titolo di risparmio, ad una compagnia di assicurazione. Questa si impegnerà nella restituzione della somma capitalizzata. Il capitale versato sarà rivalutato in base alla quota degli interessi. ',
                vantaggi: [
                  'Conservazione e crescita dei propri risparmi',
                  'Trasformazione in una rendita al termine del contratto',
                  'Formula di polizza di investimento'
                ]
              },
              {
                title: 'LONG TERM CARE ',
                description: 'La polizza prevede un sostegno economico per l’assicurato qualora si trovi incapace di svolgere le attività quotidiane a causa di un sinistro, di una malattia o dei normali processi di invecchiamento. Il vitalizio consente al beneficiario di usufruire di assistenza e adeguate cure mediche.',
                vantaggi: [
                  'Supporto continuativo nel tempo',
                  'Integrazione alle prestazioni fornite dalla assistenza pubblica'
                ]
              },
              {
                title: 'TEMPORANEA CASO MORTE  ',
                description: 'TCM rappresenta la tradizionale polizza sulla vita dell’individuo, a garanzia della tutela dei terzi, liberamente individuati dal contraente, da eventuali debiti o esposizioni finanziarie. Garantisce infatti un capitale in caso di morte dell’assicurato. Al verificarsi di questa evenienza, prima della scadenza del contratto, l’assicurazione si impegna a corrispondere la somma assicurata ai beneficiari individuati dal contraente.',
                vantaggi: [
                    'Garanzia dei costi di successione',
                    'Beneficio fiscale',
                    'Tutela degli eredi da eventuali debiti ed esposizioni finanziarie'
                ]
              },
              {
                title: 'KEY MAN',
                description: 'È la polizza a copertura della perdita (temporanea o definitiva) di una persona fondamentale per le proprie competenze e capacità all’interno dell’azienda Tramite questa garanzia, viene assicurato il danno patrimoniale che potrebbe subire l’azienda per la perdita della risorsa umana individuata e per una sua eventuale sostituzione. Si costituisce normalmente sulla base di una tariffa assicurativa a “Vita Intera”, la quale permette, all’azienda, un esborso di danaro limitato nel tempo ma una copertura che continua per l’intera vita della persona assicurata.',
                vantaggi: [
                  'Tutela delle figure chiave interne alla azienda',
                   'Garanzia di continuità della attività aziendale',
                ]
              }
            ]
          },
          {
            id: 3,
            name: 'Assicurazione casa',
            sections: [
              {
                title: 'L’assicurazione su misura per la tutela della casa e/o dell’appartamento di proprietà, oltre che per la protezione della famiglia.',
                description: 'Questa assicurazione personalizzata protegge non solo la tua casa e i suoi contenuti da eventuali danni, ma offre anche una copertura di responsabilità civile per tutta la famiglia. È ideale per chi desidera vivere serenamente, sapendo che eventuali incidenti domestici o danni a terzi sono coperti. Inoltre, permette di affrontare imprevisti con tranquillità, garantendo la sicurezza del patrimonio familiare e dei beni più preziosi.',
                vantaggi: [
                  'Copertura danni all’abitazione',
                  'Copertura danni al contenuto dell’abitazione (mobili, elettrodomestici, effetti personali)',
                  'Copertura danni che il capofamiglia o i componenti del nucleo familiare potrebbero causare ad altre persone e ai loro beni (assicurazione RC Capofamiglia)'
                ]
              }
            ]
          },
          {
            id: 4,
            name: 'Assicurazione sanitaria',
            sections: [
              {
                title: 'FORMA COLLETTIVA',
                description: 'Consiste in un programma di benefit per i dipendenti, realizzato attraverso la prestazione di coperture sanitarie che consentono all’azienda di proteggerli al meglio, ottenendo un miglioramento del clima aziendale e un risparmio sui costi del personale, grazie alle possibilità offerte dalla legislazione fiscale. Consiste nell’attivazione di un piano assicurativo sanitario per tutti i dipendenti, o anche per una sola categoria omogenea degli stessi (ad esempio gli impiegati, i quadri, ecc.) attraverso la stipula di polizze collettive o con l’adesione ad una “Cassa di assistenza”, che permette di beneficiare del principio di mutualità secondo il quale la valutazione del rischio viene effettuata sulle caratteristiche “macro” della collettività, derogando al principio assicurativo di “selezione del rischio”.',
                vantaggi: [
                  'Riduzione del costo del lavoro',
                  'Condivisione di una visione strategica tra tutti i soggetti attivi nel mondo del lavoro: impresa, organizzazioni sindacali, lavoratore',
                  'Riduzione delle assenze dovute a malattia, grazie a interventi tempestivi in strutture di elevata qualità',
                  'Vantaggi fiscali'
                ]
              },
              {
                title: 'FORMA INDIVIDUALE',
                description: 'È uno strumento fondamentale per tutelare la salute e il patrimonio del singolo e/o del nucleo famigliare. Fornisce infatti la copertura alle spese derivanti da ogni ricovero, intervento e per tutta una serie di esami specialistici, in Italia e nel mondo.',
                vantaggi: [
                'Garanzia delle spese sostenute per cure mediche adeguate',
                'Scelta dei professionisti e delle strutture sanitarie',
                'Scelta dell’ampiezza delle tutele economiche',
                'Tutela del patrimonio famigliare'
                ]
              },
              {
                title: 'IPM',
                description: 'È una copertura che mette a disposizione dell’assicurato un capitale utile in caso di invalidità permanente da malattia. La polizza infatti prevede il pagamento di un indennizzo nell’ipotesi in cui, a seguito di una malattia, il soggetto assicurato riporti un’invalidità permanente che comporti la perdita o la diminuzione definitiva ed irrimediabile della capacità a svolgere una qualsiasi attività lavorativa. L’importo del capitale riconosciuto dipende dalla somma assicurata e dal grado di invalidità accertato.',
                vantaggi: [
                'Importante sostegno economico in caso di malattie importanti',
                'Obiettiva quantificazione del danno in funzione dell’organo o apparato colpito dalla malattia',
                'Ricorso a precisi canoni di valutazione medico-legali',
                'Copertura con validità in tutto il mondo'
                ]
              }
            ]
          },
          {
            id: 5,
            name: 'Assicurazione cyber security',
            sections: [
              {
                title: 'Cyber risk è la soluzione assicurativa che permette di proteggersi dalla criminalità e dai rischi informatici.',
                description: 'Rivolta sia alle aziende che ai privati, funge da tutela dal furto e dalla manipolazione di informazioni sensibili, da attacchi informatici e da frodi operate ai danni dei propri sistemi IT. Può coprire infatti sia i danni propri in caso di interruzione di attività, proprietà intellettuale, ripristino dei sistemi, sia i danni a terzi quali violazione della privacy, perdita di profitto, frode finanziaria, costi per la difesa legale e rischio reputazionale.',
                vantaggi: [
                  'Fondamentale valore aggiunto per le aziende',
                  'Protezione dei dati',
                  'Sensibilità elevata dei sistemi per la gestione dell’operatività aziendale quotidiana',
                  'Analisi degli scenari di rischio informatico',
                  'Analisi della vulnerabilità dei sistemi',
                  'Analisi della dipendenza dei processi di business dai sistemi informativi'
                ]
              }
            ]
          },
          {
            id: 6,
            name: 'Assicurazione immobili commerciali',
            sections: [
              {
                title: 'ALL RISKS ASSET AZIENDALI ',
                description: 'La polizza All risks Asset Aziendali è una copertura indispensabile a tutela dei beni dell’azienda, come fabbricati, macchinari, merci e stampi, dai danni diretti e dalle interruzioni di attività da essi derivanti. È finalizzata infatti a tenere indenne l’assicurato dai danni diretti agli asset aziendali causati da incendio, vento, furto, trombe d’aria, alluvione, inondazione, terremoto, guasto macchine per esempio. Sono inoltre coperti i danni indiretti derivanti dai diretti coperti.',
                vantaggi: [
                'Continuità aziendale assicurata',
                'Tutela dei propri investimenti',
                'Ottimizzazione dei costi',
                'Consapevolezza dei rischi',
                'Certezza del risarcimento'
                ]
              },
              {
                title: 'DANNI INDIRETTI',
                description: 'È il prodotto assicurativo a copertura dei danni indiretti che non sono compresi in una polizza incendio o All Risks, a seguito di eventi, anche di genere catastrofica. L’obiettivo è ricostruire la funzionalità normale dei beni con la copertura della perdita del profitto lordo dovuto alla riduzione del volume d’affari e all’aumento dei costi d’esercizio, registrati durante un periodo di tempo convenuto in polizza e pari a quello presumibilmente necessario per l’azzeramento degli effetti del sinistro sull’andamento aziendale, in conseguenza di un evento.',
                vantaggi: [
                'Continuità aziendale assicurata',
                'Tutela dei propri investimenti',
                'Ottimizzazione dei costi',
                'Consapevolezza dei rischi',
                'Certezza del risarcimento'
                ]
              }
            ]
          },
          {
            id: 7,
            name: 'Assicurazione cane e gatto',
            sections: [
              {
                title: 'È la polizza a garanzia del rimborso delle spese mediche veterinarie sostenute e regolarmente documentate.',
                description: 'Questa assicurazione offre una copertura completa per il tuo cane in caso di malattia o infortunio. Include il ricovero con o senza intervento chirurgico, interventi chirurgici ambulatoriali eseguiti in strutture veterinarie autorizzate e visite specialistiche. Inoltre, copre indagini strumentali, esami di laboratorio e onorari veterinari, garantendo la migliore cura possibile per il tuo amico a quattro zampe, senza preoccupazioni economiche.',
                vantaggi: [
                  'Garanzia delle spese sostenute per cure veterinarie adeguate; tutela il patrimonio.'
                ]
              }
            ]
          },
          {
            id: 8,
            name: 'Assicurazione viaggio',
            sections: [
              {
                title: 'VIAGGI LINEA PERSONA',
                description: 'Rappresenta una soluzione assicurativa a tutela dei rischi corsi dai soggetti in viaggio. Rappresenta una soluzione assicurativa a tutela dei rischi corsi dai soggetti in viaggio, garantendo inoltre il rimborso delle spese mediche all’estero per malattia improvvisa o infortunio nei limiti del massimale specificatamente scelto e stabilito. È assicurata l’assistenza erogata per il tramite della Centrale Operativa della Compagnia assicuratrice 24 ore su 24.',
                vantaggi: [
                'Tutela della persona',
                'Sicurezza durante il viaggio',
                'Contenimento delle spese di emergenza sostenute'
                ]
              },
              {
                title: 'VIAGGI LINEA PERSONA',
                description: 'È la garanzia prestata dall’azienda in favore del personale dipendente che effettua missioni di lavoro all’estero. È la garanzia prestata dall’azienda in favore del personale dipendente che effettua missioni di lavoro all’estero, garantendo il rimborso delle spese mediche all’estero per malattia improvvisa o Infortunio nei limiti del massimale specificatamente scelto e stabilito. È inoltre assicurata l’assistenza erogata per il tramite della Centrale Operativa della Compagnia assicuratrice 24 ore su 24.',
                vantaggi: [
                'Tutela delle risorse umane dell’azienda',
                'Ottimizzazione dei costi sanitari sostenuti',
                'Contenimento delle spese di emergenza durante le missioni aziendali'
                ]
              }
            ]
          },
          {
            id: 9,
            name: 'Assistenza legale',
            sections: [
              {
                title: 'Una polizza di servizio che permette all’azienda di disporre della migliore assistenza dedicata, sia in sede stragiudiziale che giudiziale.',
                description: 'Una polizza di servizio che permette all’azienda di disporre della migliore assistenza dedicata, sia in sede stragiudiziale che giudiziale: dall’iniziale valutazione ed impostazione delle azioni da intraprendere, sino al pagamento della parcella ed alla liquidazione di ogni altra spesa legale. Questo strumento consente all’assicurato di scegliere liberamente l’avvocato senza doversi occupare del suo onorario. In questo modo tutti gli assicurati, indipendentemente dall’impegno economico che la vertenza richiede, possono far valere con forza e con maggiori probabilità di successo le proprie ragioni.',
                vantaggi: [
                  'Certezza delle spese legali e garanzia ad un’adeguata difesa dei propri diritti.'
                ]
              }
            ]
          },
          {
            id: 10,
            name: 'Assicurazione professionisti',
            sections: [
              {
                title: 'RC PROFESSIONALE',
                description: 'La RC professionale è una polizza obbligatoria come disposto dal D.P.R. 137/2012, pensata per offrire adeguata copertura assicurativa ai soggetti che svolgono un’attività lavorativa professionale e regolarmente iscritti ad un Albo. La RC professionale è una polizza obbligatoria come disposto dal D.P.R. 137/2012, pensata per offrire adeguata copertura assicurativa ai soggetti che svolgono un’attività lavorativa professionale e regolarmente iscritti ad un Albo. La sua funzione è tutelare il professionista da richieste di risarcimento per danni a terzi o a cose cagionati durante lo svolgimento della propria attività lavorativa.',
                vantaggi: [
                'Tutela nel risarcimento danni o lesioni cagionati durante l’esercizio dell’attività professionale',
                'Soluzioni personalizzate progettate per le esigenze di ciascuna categoria di professionisti',
                'Sicurezza nell’esercizio della propria professione',
                'Garanzia per i propri clienti'
                ]
              },
              {
                title: 'RESPONSABILITÀ CIVILE COLPA GRAVE ',
                description: 'È una polizza assicurativa di RC derivante da colpa grave che tutela amministratori, dirigenti e dipendenti della Pubblica Amministrazione nell’esercizio delle attività istituzionali per conto dell’Ente di appartenenza. La sua funzione è garantire le perdite patrimoniali cagionate a terzi in conseguenza a errori od omissioni dell’assicurato nell’espletamento delle proprie funzioni pubbliche.',
                vantaggi: [
                'Tutela da richieste di risarcimento',
                'Sicurezza nell’esercizio della propria funzione',
                'Protezione dalle perdite per l’interruzione o la sospensione dell’attività'
                ]
              },
              {
                title: 'RESPONSABILITÀ AMMINISTRATORI (D&O) ',
                description: 'Polizza in difesa del patrimonio degli amministratori, a tutela di dipendenti, soci e creditori, a garanzia contro richieste di risarcimento, spese legali contro le persone e la società e quelle di emergenza. D&O DIRECTORS & OFFICERS LIABILITY La D&O è una polizza di Responsabilità Civile riservata agli amministratori, di diritto o di fatto, ai sindaci e ai dirigenti d’impresa per quanto loro dovuto a terzi richiedenti, per le perdite patrimoniali da essi direttamente cagionate mediante la commissione di atti illeciti nell’esercizio delle attribuzioni e dei poteri loro conferiti in seguito allo svolgimento delle rispettive funzioni. Tali soggetti rispondono infatti con il proprio patrimonio personale in caso di colposa inosservanza dei doveri loro imposti dalla legge e dall’atto costitutivo della Società alla quale appartengono.',
                vantaggi: [
                'Difesa del patrimonio degli amministratori',
                'Tutela di dipendenti, soci e creditori',
                'Garanzia contro richieste di risarcimento',
                'Copertura delle spese legali contro le persone e la società',
                'Copertura delle spese di emergenza'
                ]
              },
              {
                title: 'EMPLOYMENT PRACTICE LIABILITY INSURANCE',
                description: 'E.P.L. è una polizza che tutela l’impresa e le persone fisiche da richieste di risarcimento formulate dai dipendenti nei confronti del datore di lavoro (società, amministratori, dirigenti) per eventi dannosi non correlati a lesioni fisiche (es. mobbing, demansionamento, discriminazioni razziali, licenziamento senza giusta causa, violazione della privacy, stalking, abuso di potere, etc.).',
                vantaggi: [
                'Difesa del patrimonio degli amministratori',
                'Tutela dei dipendenti',
                'Garanzia contro richieste di risarcimento'
                ]
              }
            ]
          },
          {
            id: 11,
            name: 'Assicurazione eventi atmosferici',
            sections: [
              {
                title: 'La polizza Meteo è una copertura assicurativa per le perdite economiche subite da un’attività a causa della variabilità delle condizioni meteorologiche.',
                description: 'La polizza Meteo è una copertura assicurativa per le perdite economiche subite da un’attività a causa della variabilità delle condizioni meteorologiche. Garantisce infatti all’assicurato la copertura dei costi sostenuti o dei mancati ricavi causati dalle condizioni meteorologiche avverse che impattano sullo svolgimento dell’attività. Sono molteplici i settori le cui prestazioni possono essere influenzate dalle condizioni climatiche: dall’agricoltura per periodi di siccità, al turismo per la troppa pioggia, fino alle energie alternative per il poco vento o poco sole.<br><br> La polizza protegge anche in caso di eventi molto gravi come: alluvioni, inondazioni e terremoti.',
                vantaggi: [
                  'Flessibilità della copertura',
                  'Possibilità di concordare le prestazioni in relazione ai picchi stagionali',
                  'Adattamento ai periodi di inizio e fine stagione',
                  'Adattamento in base ai weekend'
                ]
              }
            ]
          },
          {
            id: 12,
            name: 'Assicurazione sportiva',
            sections: [
              {
                title: 'Quando si pratica sport, non come attività agonistica e lavorativa e si vuol fare in modo che gli imprevisti sportivi si risolvano senza compromettere il proprio benessere o la propria stabilità economica.',
                description: 'In caso di infortunio, si ha a disposizione una somma giornaliera in caso di ricovero, convalescenza o immobilizzazione ed il rimborso delle spese mediche sostenute. Così ci si può ripagare le cure migliori e ripartire al più presto. Inoltre, a partire dal 1° gennaio 2022 è stato introdotto l’obbligo di munirsi di un’assicurazione per la Responsabilità Civile verso Terzi sulle piste da SCI. La normativa vigente prevede che per la pratica di sport invernali sia necessaria una copertura per danni o infortuni arrecati a terzi (RCT). Abbiamo pensato ad una soluzione completa per la pratica di: sci, snowboard, pattinaggio sul ghiaccio, ciaspole da neve e slittino!',
                vantaggi: [
                  'Rimborso spese mediche: da infortunio, ricovero a seguito di infortunio e diaria da ricovero',
                  'Responsabilità civile per danni a terzi.',
                  'Pacchetto sci: rimborso delle spese dell’hotel, skipass, lezioni e noleggio attrezzature, spese di trasporto in ambulanza, elicottero, toboga', 
                  'Assistenza in viaggio: con diverse garanzie'
                ]
              },
              {
                title: 'FINE CARRIERA',
                description: "Fine Carriera è una polizza fondamentale per la tutela della carriera agonistica di atleti professionisti, che potrebbe essere compromessa in modo definitivo da rischi e/ infortuni. Questa assicurazione garantisce l'erogazione di un capitale prestabilito in caso di eventi imprevisti. Copre l'invalidità permanente totale da infortunio o malattia, che comporta la perdita definitiva dell'idoneità all'attività fisica. Inoltre, offre protezione per l'inabilità temporanea da infortunio o malattia, coprendo il minor ingaggio in caso di assenza alle gare. Ideale per chi vuole assicurarsi tranquillità finanziaria in situazioni critiche. Tutela quindi lo sportivo dall’eventuale sopravvenuta incapacità di produrre reddito, completando la copertura parziale fornita dall’INAIL.",
                vantaggi: [
                  'Totale copertura assicurativa in caso di infortunio',
                  'Garanzia di capitale e copertura della temporanea inabilità',
                ]
              }
            ]
          },
          {
            id: 13,
            name: 'Assicurazione infortuni',
            sections: [
              {
                title: 'INFORTUNI',
                description: 'È un’assicurazione per la persona contro una diminuzione della sua capacità di produrre reddito a seguito d’infortunio. La tutela indennizza l’assicurato o i beneficiari indicati in caso di morte, l’invalidità permanente e l’inabilità temporanea. È un’assicurazione per la persona contro una diminuzione della sua capacità di produrre reddito a seguito d’infortunio. La tutela indennizza l’assicurato o i beneficiari indicati in caso di morte, l’invalidità permanente e l’inabilità temporanea.',
                vantaggi: [
                  'Consulenza nell’individuazione di somme e clausole',
                  'Garanzia del proprio patrimonio in caso di infortunio',
                  "Libera scelta dell'ammontare delle somme da assicurare per ciascun caso di infortunio" 
                ]
              },
              {
                title: 'INFORTUNI COLLETTIVI',
                description: "Rappresenta un’assicurazione per un gruppo di persone o più gruppi tra loro differenti, ma aventi ciascuno caratteristiche omogenee (es. amministratori, dirigenti, impiegati, operai), contro una diminuzione della capacità di produrre reddito a seguito d’infortunio. Rappresenta un’assicurazione per un gruppo di persone o più gruppi tra loro differenti, ma aventi ciascuno caratteristiche omogenee (es. amministratori, dirigenti, impiegati, operai), contro una diminuzione della capacità di produrre reddito a seguito d’infortunio. Si occupa infatti dell’indennizzo di ciascun assicurato o dei beneficiari indicati in caso di morte, invalidità permanente e inabilità temporanea.",
                vantaggi: [
                  'Garanzia del proprio patrimonio in caso di infortunio',
                  "Libera scelta dell'ammontare delle somme da assicurare per ciascun caso di infortunio"
                ]
              }
            ]
          },
          {
            id: 14,
            name: 'CAUZIONI',
            sections: [
              {
                title: '',
                description: ' Le cauzioni sono delle polizze fideiussorie assicurative, ovvero un contratto assicurativo atto a garantire il pieno adempimento degli accordi e impegni assunti tra le parti. Garante dell’accordo è il fideiussore, ossia l’ente assicurativo incaricato di stipulare le garanzie fideiussorie a seguito del pagamento di un premio, cioè il costo della cauzione assicurativa.',
                vantaggi: [
                  
                ]
              },
              {
                title: 'WARRANTY BOND',
                description: "È una copertura tipicamente richiesta da committenti ed investitori a garanzia della corretta costruzione dell’impianto dal PAC al FAC, da non confondere con la Performance Bond. È, a prima richiesta, escutibile dal committente o dall’investitore nel caso in cui l’appaltatore non adempia agli obblighi previsti dal contratto di rendimento energetico (Energy Performance Contract o EPC) e/o O&M (Operation and Maintenance). È possibile strutturare questo prodotto attraverso fideiussioni assicurative ottimizzando la liquidità e l’esposizione bancaria di EPC e permettendo al contempo a committenti ed investitori di ottenere i collaterali di cui necessitano",
                vantaggi: [
                  'Soluzioni alle problematiche in sospeso',
                  'Risoluzione dei contenziosi aperti',
                  'Scongelamento di eventuali retention e/o penalità attivate'
                ]
              },
              {
                title: 'PERFORMANCE BOND',
                description: "È una fideiussione richiesta a garanzia della corretta esecuzione dei lavori, a volte confusa con il Warranty Bond. Il Performance Bond garantisce committenti e investitori da eventuali inadempienze degli appaltatori nello svolgimento dei lavori. Normalmente richiesti sottoforma di fideiussione bancaria, i Performance Bond sono ottenibili anche sotto forma di polizza fideiussoria assicurativa, riducendo al minimo le immobilizzazioni di capitale dell’azienda.",
                vantaggi: [
                  'Ottimizzazione dei costi',
                  'Aumento della liquidità disponibile'
                ]
              },
              {
                title: 'WASTE DISPOSAL BOND',
                description: "È una copertura tipicamente richiesta dagli enti pubblici a garanzia dello smantellamento/decommissioning di un impianto al termine della sua vita. Tiene infatti indenne il committente dall’eventualità che il contratto di rendimento energetico (Energy Performance Contract o EPC) non rispetti gli obblighi di smantellamento previsti. Si tratta di garanzie della durata di venti o venticinque anni, tipicamente rilasciate sotto forma di bond bancario o fideiussione confidi, ma che possono essere anche rilasciate tramite fideiussioni assicurative rinnovabili.",
                vantaggi: [
                  'Soluzioni alle problematiche in sospeso',
                  'Risoluzione dei contenziosi aperti',
                  'Scongelamento di eventuali retention e/o penalità attivate'
                ]
              },
              {
                title: 'FIDEIUSSIONI RIMBORSI IVA',
                description: "È una polizza fideiussoria che garantisce il rimborso anticipato dell’IVA al contribuente in caso di credito verso l’Amministrazione Finanziaria. I contribuenti, che all’atto della presentazione della dichiarazione dell’IVA, si trovano ad essere creditori nei confronti dell’Amministrazione Finanziaria, possono chiedere e ottenere il rimborso anticipato di tale credito prestando garanzia mediante la polizza fideiussoria per rimborsi IVA.",
                vantaggi: [
                  'Garanzia di rimborso',
                  'Maggiore disponibilità di liquidità'
                ]
              }
            ]
          }
        ];
  
        this.insurance = items.find(item => item.id === parseInt(id));
      }
    },
    computed: {
    defaultInsuranceType() {
      if (!this.insurance || !this.insurance.name) return null;
      return {
        value: this.insurance.name.toLowerCase().replace(/\s+/g, '-'),
        text: this.insurance.name
      };
    }
  }
  };
  </script>
  
  <style scoped>

  .sidebar_container {
    min-width: 35%;
    margin-right: 30px;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  h3{
    font-size: 18px;
    font-weight: 700;
  }
  
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  
  ul {
    list-style: none;
    font-size: 18px;
    margin-bottom: 25px;
  }
  
  li {
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #16243D;
  }
  
  .fa-circle-check {
    color: var(--primary-color);
  }

  .mobile_contact{
      display: none;
  }

  .form_title{
    font-size: 25px;
    font-weight: 700;
    margin-top: 50px;
  }

  @media (max-width: 980px) {
    .sidebar_container{
      display: none;
    }

    .mobile_contact{
      display: block;
  }

  }

  </style>
  